import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Api } from '../../common/Api';
import {
    LinearProgress,
    CardHeader,
    CardActions,
    Button,
    Card,
    Grid,
    Typography,
    Link,
} from '@material-ui/core';
import { MsgSnackbar } from '../../components';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    bodyContent: {
        margin: theme.spacing(2),
    },
}));

const CreateJob = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [process, setProcess] = useState(null);
    const [showMsg, setShowMsg] = useState(false);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        const api = new Api();
        if (process === null) {
            api.getProcess(props.match.params.id)
                .then(data => {
                    setProcess(data);
                    setLoading(false);
                    setError(null);
                })
                .catch(err => {
                    if (err.status === 401) {
                        props.history.push(
                            '/login?ref=' + props.location.pathname,
                        );
                    }
                    setError(err.error);
                    setShowMsg(true);
                });
        }
    }, [process, props]);

    const handleExample = async event => {
        const api = new Api();
        api.getProcessExample(props.match.params.id)
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            })
            .catch(err => {
                if (err.status === 401) {
                    props.history.push('/login?ref=' + props.location.pathname);
                }
                setError(err.error);
                setShowMsg(true);
            });
    };
    const handleSelected = async event => {
        const files = event.target.files;
        setFile(files[0]);
    };
    const handleCreateJob = async event => {
        setLoading(true);
        const api = new Api();
        api.createJob(props.match.params.id, file)
            .then(data => {
                setError(null);
                setShowMsg(true);
                props.history.push('/');
            })
            .catch(err => {
                if (err.status === 401) {
                    props.history.push('/login?ref=' + props.location.pathname);
                }
                setFile(null);
                setError(err.error ? err.error : 'An Error Has Ocurred');
                setShowMsg(true);
                setLoading(false);
            });
    };

    if (loading || process === null) {
        return (
            <div className={classes.rootLoad}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <div className={classes.content}>
                    <Card className={classes.card} elevation={3}>
                        <CardHeader
                            title={
                                <div className={classes.titleCard}>
                                    <Grid container justify="space-between">
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                        >
                                            <Typography
                                                component="h3"
                                                variant="h3"
                                            >
                                                New
                                                <strong>
                                                    {' ' + process.Name + ' '}
                                                </strong>
                                                Process
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        ></CardHeader>
                    </Card>
                    <form>
                        <div className={classes.bodyContent}>
                            <Typography variant="h4" color="textSecondary">
                                To start a new job please select a CSV to
                                upload.
                            </Typography>
                            <Typography variant="h4" color="textSecondary">
                                The CSV file should have the same format as this{' '}
                                <Link href="#" onClick={handleExample}>
                                    example.
                                </Link>
                            </Typography>
                            <div className={classes.bodyContent}>
                                <input
                                    id="fileUpload"
                                    className={classes.input}
                                    type="file"
                                    accept=".csv"
                                    onChange={handleSelected}
                                ></input>
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleCreateJob}
                                disabled={file == null}
                            >
                                Upload and Start Job
                            </Button>
                        </CardActions>
                    </form>
                </div>
            </div>
        );
    }
};

CreateJob.propTypes = {
    props: PropTypes.object,
};

export default withRouter(CreateJob);
