import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField,
    Typography,
    LinearProgress,
} from '@material-ui/core';
import { Api } from '../../common/Api';

const schema = {
    username: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    grid: {
        height: '100%',
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300,
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    bio: {
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        marginTop: theme.spacing(3),
    },
    sugestion: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    signInButton: {
        margin: theme.spacing(2, 0),
    },
}));

const SignIn = props => {
    const classes = useStyles();
    const api = new Api();
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const ref = params.get('ref') ? params.get('ref') : '/dashboard';

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(form => ({
            ...form,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);
    const handleChange = event => {
        event.persist();
        setFormState(form => ({
            ...form,
            values: {
                ...form.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...form.touched,
                [event.target.name]: true,
            },
        }));
    };

    const handleSignIn = async event => {
        event.preventDefault();
        setLoading(true);
        api.loginUser(formState.values.username, formState.values.password)
            .then(data => {
                localStorage.setItem('token', data.token);
                setRedirect(true);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    if (loading) {
        return (
            <div className={classes.root}>
                <LinearProgress />
            </div>
        );
    } else if (redirect) {
        return <Redirect to={ref} />;
    } else {
        return (
            <div className={classes.root}>
                <Grid
                    className={classes.grid}
                    container
                    alignItems={'center'}
                    alignContent={'center'}
                >
                    <Grid className={classes.content} item lg={12} xs={12}>
                        <div className={classes.content}>
                            <div className={classes.contentBody}>
                                <form
                                    className={classes.form}
                                    onSubmit={handleSignIn}
                                >
                                    <Typography
                                        className={classes.title}
                                        variant="h2"
                                    >
                                        Login
                                    </Typography>
                                    <Typography
                                        align="center"
                                        className={classes.sugestion}
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        Login with username
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        error={hasError('username')}
                                        fullWidth
                                        helperText={
                                            hasError('username')
                                                ? formState.errors.username[0]
                                                : null
                                        }
                                        label="Username"
                                        name="username"
                                        onChange={handleChange}
                                        type="text"
                                        value={formState.values.username || ''}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        error={hasError('password')}
                                        fullWidth
                                        helperText={
                                            hasError('password')
                                                ? formState.errors.password[0]
                                                : null
                                        }
                                        label="Password"
                                        name="password"
                                        onChange={handleChange}
                                        type="password"
                                        value={formState.values.password || ''}
                                        variant="outlined"
                                    />
                                    <Button
                                        className={classes.signInButton}
                                        color="primary"
                                        disabled={!formState.isValid || loading}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign in now
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

SignIn.propTypes = {
    history: PropTypes.object,
};

export default withRouter(SignIn);
