import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const CustomLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <RouterLink {...props} />
    </div>
));

export default CustomLink;
