import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Grid,
    LinearProgress,
    CardHeader,
    Divider,
    Typography,
} from '@material-ui/core';
import { ProjectCard } from './components';
import { Api } from '../../common/Api';
import MaterialTable from 'material-table';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    tableHead: {
        background: theme.palette.primary,
    },
}));

const Dashboard = props => {
    const classes = useStyles();

    const [dashboardData, setdashboardData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (dashboardData === null) {
            const api = new Api();
            api.getDashboard()
                .then(data => {
                    setdashboardData(data);
                    setLoading(false);
                })
                .catch(err => handleErr(err));
        }
    });
    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
    };
    if (loading) {
        return (
            <div className={classes.rootLoad}>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <Typography gutterBottom variant="h4">
                        Company - <strong>{dashboardData.Company}</strong>
                    </Typography>
                    <CardHeader title="Processes" />
                    <Divider />
                    <Grid container className={classes.content} spacing={3}>
                        {dashboardData.Processes.map(product => (
                            <Grid item key={product.id} lg={4} md={6} xs={12}>
                                <ProjectCard project={product} />
                            </Grid>
                        ))}
                    </Grid>
                    <Divider className={classes.content} />
                    <MaterialTable
                        title="Jobs"
                        columns={[
                            {
                                title: 'Process Name',
                                field: 'ProcessName',
                                render: row => (
                                    <RouterLink to={'/manage-job/' + row.id}>
                                        {row.ProcessName}
                                    </RouterLink>
                                ),
                            },
                            {
                                title: 'Status',
                                field: 'Status',
                                lookup: {
                                    WAITING: 'WAITING',
                                    RUNNING: 'RUNNING',
                                    DONE: 'DONE',
                                },
                            },
                            { title: 'Message', field: 'Message' },
                            {
                                title: 'Created',
                                field: 'CreatedAt',
                                render: row =>
                                    moment(row.CreatedAt).format(
                                        'YYYY-MM-DD HH:mm',
                                    ),
                            },
                            {
                                title: 'Updated',
                                field: 'UpdatedAt',
                                render: row =>
                                    moment(row.UpdatedAt).format(
                                        'YYYY-MM-DD HH:mm',
                                    ),
                            },
                        ]}
                        data={dashboardData.Jobs}
                        options={{
                            pageSize: 10,
                        }}
                    />
                </div>
            </div>
        );
    }
};
Dashboard.propTypes = {
    props: PropTypes.object,
};
export default withRouter(Dashboard);
