const API_URL = process.env.REACT_APP_API_URL;
export const URL = {
    LOGIN: API_URL + '/login',
    GETUSER: API_URL + '/profile',
    GETDASHBOARD: API_URL + '/dashboard',
    GETPROCESS: API_URL + '/process/~id~',
    GETPROCESSEXAMPLE: API_URL + '/process/~id~/example',
    CREATEJOB: API_URL + '/new-job/~id~',
    GETJOB: API_URL + '/job/~id~',
    GETJOBFILE: API_URL + '/job/~id~/file',
    GETJOBERROR: API_URL + '/job/~id~/error',
    RESTARTJOB: API_URL + '/job/~id~/restart',
    STOPJOB: API_URL + '/job/~id~/stop',
};

export class Api {
    authHeader = { Authorization: 'Bearer ' + localStorage.getItem('token') };
    getOption = {
        method: 'GET',
        headers: this.authHeader,
    };
    postOption = {
        method: 'POST',
        headers: this.authHeader,
    };
    delOption = {
        method: 'DELETE',
        headers: this.authHeader,
    };

    async returnResp(res) {
        if (!res.ok) {
            let error = await res.json();
            return Promise.reject(error);
        }
        const data = await res.json();
        return data;
    }
    async loginUser(user, pass) {
        const loginReq = {
            username: user,
            password: pass,
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(loginReq),
        };
        const res = await fetch(URL.LOGIN, options);
        return this.returnResp(res);
    }
    async getUser() {
        let url = URL.GETUSER;
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async getDashboard() {
        const res = await fetch(URL.GETDASHBOARD, this.getOption);
        return this.returnResp(res);
    }
    async getProcess(id) {
        let url = URL.GETPROCESS;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async getProcessExample(id) {
        let url = URL.GETPROCESSEXAMPLE;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return res;
    }
    async getJobFile(id) {
        let url = URL.GETJOBFILE;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return res;
    }
    async getJobError(id) {
        let url = URL.GETJOBERROR;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return res;
    }
    async createJob(id, file) {
        let url = URL.CREATEJOB;
        url = url.replace('~id~', id);
        const formData = new FormData();
        formData.append('file', file);
        const options = {
            method: 'POST',
            headers: this.authHeader,
            body: formData,
        };
        const res = await fetch(url, options);
        return this.returnResp(res);
    }
    async getJob(id) {
        let url = URL.GETJOB;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async restartJob(id) {
        let url = URL.RESTARTJOB;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
    async stopJob(id) {
        let url = URL.STOPJOB;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
}
