const checked = (value, options) => {
    if (value !== true) {
        return options.message || 'must be checked';
    }
};

export default {
    checked,
};
export function EscapeXML(aux) {
    return aux
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
}
export function DecodeXML(aux) {
    return aux
        .replace(/&apos;/g, "'")
        .replace(/&quot;/g, '"')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/&amp;/g, '&');
}
