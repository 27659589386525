import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider,
    Button,
} from '@material-ui/core';
import CustomLink from '../../../../components/CustomLink';

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: '100%',
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1),
    },
    linkButton: {
        textTransform: 'none',
    },
}));

const ProjectCard = props => {
    const { className, project, ...rest } = props;

    const classes = useStyles();

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
            raised={true}
            elevation={2}
        >
            <CardContent>
                <Typography align="center" gutterBottom variant="h4">
                    {project.Name}
                </Typography>
            </CardContent>
            <Divider />
            <CardActions>
                <Grid container justify="space-between">
                    <Grid className={classes.statsItem} item>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.Button}
                            component={CustomLink}
                            to={'/new-job/' + project.id}
                        >
                            New Job
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

ProjectCard.propTypes = {
    className: PropTypes.string,
    project: PropTypes.object.isRequired,
};

export default ProjectCard;
