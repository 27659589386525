import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
    SignIn as SignInView,
    NotFound as NotFoundView,
    Dashboard as DashboardView,
    CreateJob as NewJobView,
    ManageJob as ManageJobView,
} from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <RouteWithLayout
                component={NewJobView}
                exact
                layout={MainLayout}
                path="/new-job/:id"
            />
            <RouteWithLayout
                component={ManageJobView}
                exact
                layout={MainLayout}
                path="/manage-job/:id"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
