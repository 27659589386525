import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Api } from '../../common/Api';
import {
    LinearProgress,
    Grid,
    Card,
    Typography,
    CardHeader,
    IconButton,
    Divider,
    CardContent,
    CardActions,
    Button,
    Link,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { MsgSnackbar } from '../../components';
import moment from 'moment';
import MaterialTable from 'material-table';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    titleCard: {
        padding: theme.spacing(2),
    },
    paper: {
        height: 140,
        width: 100,
    },
    jenkinsOutput: {
        whiteSpace: 'pre-line',
    },
}));

const ManageJob = props => {
    const classes = useStyles();
    const api = new Api();
    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState(null);
    const [showMsg, setShowMsg] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (job === null) {
            api.getJob(props.match.params.id)
                .then(data => {
                    setJob(data);
                    setLoading(false);
                    setError(null);
                })
                .catch(err => {
                    if (err.status === 401) {
                        props.history.push(
                            '/login?ref=' + props.location.pathname,
                        );
                    }
                    setError(err.error);
                    setShowMsg(true);
                });
        }
    }, [api, job, props]);
    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
        setError(err.error);
        setShowMsg(true);
        setLoading(false);
    };
    const handleRefresh = event => {
        setLoading(true);
        setJob(null);
    };
    const handleSuccess = data => {
        setError(null);
        setShowMsg(true);
        setJob(null);
    };
    const handleFile = async event => {
        api.getJobFile(props.match.params.id)
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            })
            .catch(err => {
                handleErr(err);
            });
    };
    const handleErrorFile = async event => {
        api.getJobError(props.match.params.id)
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            })
            .catch(err => {
                handleErr(err);
            });
    };
    const handleRestart = event => {
        setLoading(true);
        api.restartJob(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleStop = event => {
        setLoading(true);
        api.stopJob(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };

    if (loading || job === null) {
        return (
            <div className={classes.rootLoad}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <div className={classes.content}>
                    <Card className={classes.card} elevation={3}>
                        <CardHeader
                            title={
                                <div className={classes.titleCard}>
                                    <Grid container justify="space-between">
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                        >
                                            <Typography
                                                component="h3"
                                                variant="h3"
                                            >
                                                {job.ProcessName}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Job
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                        ></Grid>
                                    </Grid>
                                </div>
                            }
                            action={
                                <IconButton
                                    aria-label="refresh"
                                    onClick={handleRefresh}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            }
                        ></CardHeader>
                    </Card>
                </div>
                <Card className={classes.content}>
                    <CardHeader title="Job Information" />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        {job.Type}
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Type
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        {job.Status}
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Status
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        {job.Message}
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Message
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        {job.Code}
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Company Code
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        <Link href="#" onClick={handleFile}>
                                            upload.csv
                                        </Link>
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Uploaded File
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        <Link
                                            href="#"
                                            onClick={handleErrorFile}
                                        >
                                            errors.csv
                                        </Link>
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Errors File
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        {moment(job.CreatedAt).format(
                                            'YYYY-MM-DD HH:mm',
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Created At
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <div className={classes.cardAttr}>
                                    <Typography component="h6" variant="h6">
                                        {moment(job.UpdatedAt).format(
                                            'YYYY-MM-DD HH:mm',
                                        )}
                                    </Typography>
                                    <Typography
                                        variant="overline"
                                        color="textSecondary"
                                    >
                                        Updated At
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <Grid container justify="space-between">
                            <Grid className={classes.statsItem} item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleRestart}
                                    disabled={job.Status !== 'DONE'}
                                >
                                    Restart Job
                                </Button>
                            </Grid>
                            <Grid className={classes.statsItem} item>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    disabled={job.Status !== 'RUNNING'}
                                    onClick={handleStop}
                                >
                                    Stop Job
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
                <div className={classes.content}>
                    <MaterialTable
                        title="Current Errors"
                        columns={[
                            {
                                title: 'AccountNumber',
                                field: 'Account',
                            },
                            { title: 'Error Messages', field: 'Errors' },
                        ]}
                        data={job.Errors}
                        options={{
                            pageSize: 10,
                        }}
                    />
                </div>
            </div>
        );
    }
};

ManageJob.propTypes = {
    props: PropTypes.object,
};

export default withRouter(ManageJob);
